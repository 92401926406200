// 公用常量
const constant = {
  // 接口地址
  API_DOMAIN: {
    // 接口地址
    0: {
      // DEV: "dev",
      // DEV: "http://8.137.118.190:8080", // 开发接口地址
      DEV: "http://192.168.2.13:8942",
      // DEV: "http://192.168.2.11:8080",
      // TEST: "http://47.109.62.235:8080", // 测试接口地址
      PRODUCT: "https://www.cq-nydata.xzhlwl.cn/api", // 线上接口地址
      // PRODUCT: "http://47.109.62.235:8080", // 线上接口地址
    },
  },
};

export default constant;
